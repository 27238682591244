import { addDays } from "date-fns";

export const BerthFunctions = {
  bookedDays,
  statusDaysExcludingBookedDays,
  atLeastOneDayChangesStatusToEnabled,
  statusDays,
  near,
  nearB,
  distance,
  distanceB,
};

function wouldChangesStatusToEnabled(start, end, futureStatuses) {
  start.setHours(12);
  end.setHours(12);
  let statusesSet = new Set(statusDays(futureStatuses).map((d) => d.getTime()));
  let notAlreadyEnabled = expandDays(start.getTime(), end.getTime())
    .map((d) => d.getTime())
    .filter((candidateDay) => !statusesSet.has(candidateDay))
    .map((c) => new Date(c));
  return notAlreadyEnabled;
}

function atLeastOneDayChangesStatusToEnabled(start, end, futureStatuses) {
  return wouldChangesStatusToEnabled(start, end, futureStatuses).length > 0;
}

function statusDays(statuses) {
  return statuses
    .map((st) => [Date.parse(st.from), Date.parse(st.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]));
}

function bookedDays(bookings) {
  return bookings
    .map((b) => [Date.parse(b.from), Date.parse(b.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]));
}

function statusDaysExcludingBookedDays(statuses, bookings) {
  const dbSet = new Set(bookedDays(bookings).map((d) => d.getTime()));

  return statuses
    .map((st) => [Date.parse(st.from), Date.parse(st.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]))
    .filter((d) => !dbSet.has(d.getTime()));
}

function expandDays(from, to) {
  let dayCount = (to - from) / 86400000;
  let range = Array.from({ length: dayCount }, (value, index) => index);
  let rv = range.map((count) => addDays(new Date(from), count));
  return rv;
}
const oneMeter = 0.000009009;

function nearB(thisBerth, berth, distanceInMeters) {
  const d = distanceB(thisBerth, berth);

  return d < distanceInMeters * oneMeter;
}

function distance(coords, berth) {
  const y0 = coords.latitude;
  const y1 = berth.latitude;
  const x0 = coords.longitude;
  const x1 = berth.longitude;
  const yDelta = parseFloat(y1) - parseFloat(y0);
  const xDelta = parseFloat(x1) - parseFloat(x0);
  return Math.sqrt(yDelta * yDelta + xDelta * xDelta);
}
function near(latLng, berth, distanceInMeters) {
  const distanceV = distance(latLng, berth);

  const rv = distanceV < distanceInMeters * oneMeter;
  if (berth.label === "A 2") {
    console.log(`latLng: ${JSON.stringify(latLng)}`);
    console.log(`distance is ${distanceV / oneMeter} meters`);
    console.log(`Returning ${rv}`);
  }

  return rv;
}

function distanceB(thisBerth, berth) {
  const y0 = thisBerth.latitude;
  const y1 = berth.latitude;
  const x0 = thisBerth.longitude;
  const x1 = berth.longitude;
  const yDelta = parseFloat(y1) - parseFloat(y0);
  const xDelta = parseFloat(x1) - parseFloat(x0);
  return Math.sqrt(yDelta * yDelta + xDelta * xDelta);
}

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  FormText,
  FormGroup,
  Row,
  Col,
  Button,
  Container,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import { CountryDropdown } from "react-country-region-selector";
import { HBApi } from "./HBApi";

let priorityCountries = ["Sweden", "Denmark"];
const br = "8px";
const SignUp = () => {
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    country: "Sweden",
    password: "",
    boatType: "sail",
  });
  const [error409, setError409] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [showResendVerification, setShowResendVerification] = useState(200);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setError409(false);
    }
    setProfile({ ...profile, [name]: value });
  };

  const toApiParam = () => {
    return {
      email: profile.email,
      password: profile.password,
      firstName: profile.firstName,
      lastName: profile.lastName,
      phone: profile.phone,
      country: profile.country,
      boatDetails: {
        boatType: profile.boatType,
        boatModel: profile.boatModel,
        boatName: profile.boatName,
        length: profile.length,
        width: profile.width,
        depth: profile.depth,
      },
    };
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting signup form" + profile.email);
    HBApi.signUp(toApiParam())
      .then((response) => {
        if (response.ok) {
          console.log("Successfully processed sign up form");
          navigate("/signupsuccess");
        }
        return Promise.reject(response);
      })

      .catch((errorResponse) => {
        console.log("errorResponse " + errorResponse.status);
        if (errorResponse.status === 409) {
          console.log("Setting setError409(true)");
          setError409(true);
        } else if (errorResponse.status === 401) {
          console.log("Error response is 401 ");
          setError409(false);
        }
      });
  };

  const handleResend = async (event) => {
    event.preventDefault();
    console.log("Sending request to resend verification email");
    HBApi.requestResend(profile.email);
  };

  const asyncEmailVerification = async (event) => {
    event.preventDefault();
    console.log(`asynchronous email verification`);
    HBApi.validateEmail(profile.email).then((response) => {
      if (response.status === 400) {
        console.log("Email invalid");
        setEmailInvalid(true);
      } else if (response.ok) {
        console.log("Email valid");
        setEmailInvalid(false);
        HBApi.mayResendVerification(profile.email).then((response) => {
          if (response.ok) {
            setShowResendVerification(200);
          } else setShowResendVerification(response.status);
        });
      }
    });
  };

  const formValid = () => {
    if (!profile.country || profile.country === "") return false;
    if (
      !profile.email ||
      !profile.email.includes("@") ||
      profile.password.length < 8 ||
      !profile.firstName ||
      (profile.firstName && profile.firstName.length < 2) ||
      !profile.lastName ||
      (profile.lastName && profile.lastName.length < 2) ||
      !profile.phone
    ) {
      return false;
    }
    if (
      !profile.boatName &&
      !profile.boatModel &&
      !profile.length &&
      !profile.width &&
      !profile.depth
    ) {
      return true;
    }
    if (
      profile.boatName &&
      profile.boatModel &&
      profile.boatType &&
      profile.length > 0 &&
      profile.width > 0 &&
      profile.depth > 0
    ) {
      return true;
    }

    return false;
  };

  const SignUpButton = () => {
    if (formValid()) {
      return (
        <Button
          id="signUpButtonId"
          size="lg"
          color="primary"
          type="submit"
          onClick={handleSubmit.bind(this)}
        >
          Sign up
        </Button>
      );
    } else {
      return (
        <Button
          id="signUpButtonId"
          disabled
          size="lg"
          color="primary"
          type="submit"
        >
          Sign up
        </Button>
      );
    }
  };

  const EmailFormFeedback = () => {
    if (error409) {
      return <FormFeedback>Email already in use</FormFeedback>;
    } else {
      return <FormFeedback>Invalid email address</FormFeedback>;
    }
  };
  const ResendInfo = () => {
    if (showResendVerification === 200) {
      return <div></div>;
    } else if (showResendVerification === 404) {
      return <div></div>;
    } else if (showResendVerification === 409) {
      return (
        <InputGroup>
          <Toast>
            <ToastHeader>Pending email verification</ToastHeader>
            <ToastBody>
              There is an ongoing signup process for this email. In case you
              have already signed up recently and have not received the
              verification email. Click the button below to resend the
              verification email. <br></br> (Make sure you have checked your
              spam folder)
              <Button onClick={handleResend.bind(this)}>
                Resend verification email
              </Button>
            </ToastBody>
          </Toast>
        </InputGroup>
      );
    } else if (showResendVerification === 423) {
      return (
        <InputGroup>
          <Toast>
            <ToastHeader>Pending email verification</ToastHeader>
            <ToastBody>
              There is an ongoing signup process for this email created very
              recently. In case you have already signed up recently and have not
              received the verification email:
              <ul>
                <li>Wait for a couple of minutes</li>
                <li>
                  Check your spam folder, in rare cases the verification email
                  may end up here
                </li>
                <li>
                  If you still have not received the verification email return
                  here and you will be able to have the email resent.
                </li>
              </ul>
              <Button disabled>Resend verification soon available</Button>
            </ToastBody>
          </Toast>
        </InputGroup>
      );
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
          <Col md="8">
            <Form
              className="bg-light border"
              style={{ "border-radius": "15px" }}
              onSubmit={handleSubmit}
            >
              <h2 align="center">Sign up</h2>

              <FormGroup row>
                <Col>
                  <Label for="email">Email</Label>
                  <InputGroup>
                    {error409 || emailInvalid ? (
                      <Input
                        style={{ "border-radius": br }}
                        id="email"
                        name="email"
                        type="text"
                        bsSize="md"
                        autoComplete="email"
                        invalid
                        onChange={handleChange}
                        onBlur={asyncEmailVerification}
                      />
                    ) : (
                      <Input
                        style={{ "border-radius": br }}
                        id="email"
                        name="email"
                        type="text"
                        bsSize="md"
                        autoComplete="email"
                        onChange={handleChange}
                        onBlur={asyncEmailVerification}
                      />
                    )}
                    <EmailFormFeedback />
                  </InputGroup>
                  <ResendInfo />
                  <Label for="password">Password</Label>
                  <InputGroup>
                    <Input
                      style={{ "border-radius": br }}
                      id="password"
                      name="password"
                      type="password"
                      bsSize="md"
                      onChange={handleChange}
                    />
                    <FormFeedback>Password too weak</FormFeedback>
                  </InputGroup>
                  <FormText>Minimum 8 characters</FormText>

                  <PasswordStrengthBar
                    minLength="8"
                    password={profile.password}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <h5>About you</h5>
                <Col>
                  <Label for="firstName">First name</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.firstName}
                    id="firstName"
                    bsSize="md"
                    type="text"
                    name="firstName"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />

                  <Label for="lastName">Last name</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.lastName}
                    id="lastName"
                    bsSize="md"
                    type="text"
                    name="lastName"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="phone">Phone number</Label>
                  <PhoneInput
                    style={{
                      borderRadius: br,
                      backgroundColor: "white",
                      height: "40px",
                    }}
                    id="phone"
                    defaultCountry="SE"
                    placeholder="Enter phone number"
                    value={profile.phone}
                    onChange={(val) =>
                      handleChange({ target: { name: "phone", value: val } })
                    }
                  />
                </Col>
                {/* <Col>
                  <Label for="phone">Phone number</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.phone}
                    id="phone"
                    bsSize="md"
                    type="text"
                    minLength="8"
                    maxLength="30"
                    name="phone"
                    onChange={handleChange}
                  />
                </Col> */}
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="country">Country</Label>
                  <InputGroup>
                    <CountryDropdown
                      style={{
                        "border-radius": br,
                        "background-color": "white",
                        height: "40px",
                      }}
                      id="country"
                      name="country"
                      value={profile.country}
                      priorityOptions={priorityCountries}
                      onChange={(val) =>
                        handleChange({
                          target: { name: "country", value: val },
                        })
                      }
                    />
                    <FormFeedback>Please select country</FormFeedback>
                  </InputGroup>
                </Col>
              </FormGroup>
              <hr />
              <FormGroup row>
                <h5>About your boat</h5>
              </FormGroup>
              <FormGroup row>
                <Col>(Fill out all or nothing about your boat)</Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="boatModel">Boat model</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="boatModel"
                    value={profile.boatModel}
                    bssize="md"
                    type="text"
                    name="boatModel"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="boatType">Boat type</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.boatType}
                    id="boatType"
                    bssize="md"
                    type="select"
                    name="boatType"
                    onChange={handleChange}
                  >
                    <option key="sail" value="sail">
                      Sail
                    </option>
                    <option key="motor" value="motor">
                      Motor
                    </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col>
                  <Label for="boatName">Boat name</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="boatName"
                    value={profile.boatName}
                    bssize="md"
                    type="text"
                    name="boatName"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col>
                  <Label for="length">Length</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="length"
                    value={profile.length}
                    bssize="md"
                    type="number"
                    name="length"
                    placeholder="In meters"
                    minLength="2"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Label for="width">Beam</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="width"
                    value={profile.width}
                    bssize="md"
                    type="number"
                    name="width"
                    placeholder="In meters"
                    minLength="2"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Label for="depth">Draft</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="depth"
                    value={profile.depth}
                    bssize="md"
                    type="number"
                    name="depth"
                    placeholder="In meters"
                    minLength="2"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <InputGroup>
                  <SignUpButton />
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SignUp;

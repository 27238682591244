import { useLocation } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";

const BookingConflict = () => {
  
  return (
    <Container>
      <Row>
        <Col md="12">
          <AppNavbarLight />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h1 align="center">Booking conflict</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          Unfortunately someone else has made a simultaneous booking of the same
          berth. Please try booking another berth.
        </Col>
      </Row>
    </Container>
  );
};

export default BookingConflict;

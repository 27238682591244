import "./App.css";
import Home from "./Home";
import About from "./About";
import Harbour from "./Harbour";
import SignIn from "./SignIn";
import SignOut from "./SignOut";
import SignUp from "./SignUp";
import SignUpSuccess from "./SignUpSuccess";
import BookingDetails from "./BookingDetails";
import BookingConfirmation from "./BookingConfirmation";
import BookingInformation from "./BookingInformation";
import BookingConflict from "./BookingConflict";
import VerifyEmail from "./VerifyEmail";
import Profile from "./Profile";
import FutureBookings from "./FutureBookings";
import FeeSupport from "./admin/FeeSupport";
import HarbourEmbedded from "./embedded/HarbourEmbedded";
import BookingDetailsEmbedded from "./embedded/BookingDetailsEmbedded";
import { AuthProvider } from "./AuthContext";
import { useAuth } from "./AuthContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import BookingsHistory from "./BookingsHistory";
import WithNav from "./WithNav";
import WithNavAndFooter from "./WithNavAndFooter";
import MyPageNoNav from "./MyPageNoNav";
import MyHarbourNoNav from "./MyHarbourNoNav";
import BookingCancellationAnonymous from "./BookingCancellationAnonymous";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";

const React = require("react"); // <1>
const ReactDOM = require("react-dom"); // <2>

function ProtectedRoute({ children, nextpage }) {
  const auth = useAuth();
  const user = auth.getUser();

  console.log(
    `Enter ProtectedRoute User: ${JSON.stringify(
      user
    )} and next page: ${JSON.stringify(nextpage)}`
  );
  if (!user) {
    return <Navigate to="/signin" state={{ nextpage: nextpage }} />;
  }

  return children;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/admin/feesupport"
            element={
              <ProtectedRoute nextpage="/admin/feesupport">
                <WithNav>
                  <FeeSupport />
                </WithNav>
              </ProtectedRoute>
            }
          />

          <Route exact path="/about" element={<About />}></Route>

          <Route
            path="/harbours/:id"
            exact={true}
            element={
              <WithNavAndFooter>
                <Harbour />
              </WithNavAndFooter>
            }
          />
          <Route
            path="/embedded/harbours/:id"
            exact={true}
            element={<HarbourEmbedded />}
          />

          <Route
            path="/bookings/:id"
            exact={true}
            element={<BookingInformation />}
          />
          <Route
            path="/bookingdetails"
            element={
              <WithNavAndFooter>
                <BookingDetails />
              </WithNavAndFooter>
            }
          />
          <Route
            path="/embedded/bookingdetails"
            element={<BookingDetailsEmbedded />}
          />
          <Route
            path="bookingconfirmation"
            element={
              <WithNav>
                <BookingConfirmation />
              </WithNav>
            }
          />
          <Route
            path="embedded/bookingconfirmation"
            element={
            
                <BookingConfirmation />
              
            }
          />
          <Route
            path="/cancellation/:id"
            element={
              <WithNavAndFooter>
                <BookingCancellationAnonymous />
              </WithNavAndFooter>
            }
          />
          {/* <Route
            path="/myharbour"
            element={
              <ProtectedRoute nextpage="/myharbour">
                <MyHarbour />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/mypage"
            element={
              <ProtectedRoute nextpage="/mypage">
                <WithNav>
                  <MyPageNoNav>
                    <Profile />
                  </MyPageNoNav>
                </WithNav>
              </ProtectedRoute>
            }
          />
          <Route
            path="/mypage/myberth"
            element={
              <ProtectedRoute nextpage="/mypage/myberth">
                <WithNav>
                  <MyPageNoNav>
                    <MyHarbourNoNav />
                  </MyPageNoNav>
                </WithNav>
              </ProtectedRoute>
            }
          />
          <Route
            path="/mypage/futurebookings"
            element={
              <ProtectedRoute nextpage="/mypage/futurebookings">
                <WithNav>
                  <MyPageNoNav>
                    <FutureBookings />
                  </MyPageNoNav>
                </WithNav>
              </ProtectedRoute>
            }
          />
          <Route
            path="/mypage/bookingshistory"
            element={
              <ProtectedRoute nextpage="/mypage/bookingshistory">
                <WithNav>
                  <MyPageNoNav>
                    <BookingsHistory />
                  </MyPageNoNav>
                </WithNav>
              </ProtectedRoute>
            }
          />
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/forgotpassword"
            element={
              <WithNav>
                <ForgotPassword />
              </WithNav>
            }
          />
          <Route
            path="/resetpassword/:id"
            element={
              <WithNav>
                <ResetPassword />
              </WithNav>
            }
          />
          <Route path="/signout" element={<SignOut />} />
          <Route
            path="/signup"
            element={
              <WithNav>
                <SignUp />
              </WithNav>
            }
          />
          <Route
            path="/signupsuccess"
            element={
              <WithNav>
                <SignUpSuccess />
              </WithNav>
            }
          />
          <Route
            path="/verifyemail/:id"
            exact={true}
            element={
              <WithNav>
                <VerifyEmail />
              </WithNav>
            }
          />
          <Route path="/bookingconflict" element={<BookingConflict />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

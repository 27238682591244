import { Link, useLocation } from "react-router-dom";
import { List, Button, Row, Col, Container, Spinner } from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";
import { useEffect, useState } from "react";
import { HBApi } from "./HBApi";

const BookingConfirmation = () => {
  let location = useLocation();

  let bookingIds = location.state.bookingIds;
  const [references, setReferences] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log("Enter BookingConfirmation with bookingIds: " + bookingIds);

  useEffect(() => {
    console.log("Enter useEffect, will fetch booking reference(s)");
    async function fetchReferences() {
      const referenceResult = await HBApi.getBookingReferences(bookingIds);
      console.log(
        `Fetched booking references in useEffect  ${JSON.stringify(
          referenceResult
        )}`
      );
      setReferences(referenceResult);
    }

    fetchReferences().then((result) => {
      setLoading(false);
    });
  }, []);

  const BookingIds = () => {
    let bookingIdsString = bookingIds.map((s) => ` ${s}  `).toString();
    let bookingIdsTruncated = bookingIdsString.substring(
      0,
      bookingIdsString.length
    );
    if (bookingIds.length === 1) {
      return (
        <Col id="bookingConfirmationId">Booking id: {bookingIdsTruncated}</Col>
      );
    } else {
      return (
        <List>
          {bookingIds.map((id) => {
            return (
              <li id={"bookingIdNo-" + bookingIds.indexOf(id)}>
                <b>Booking id {bookingIds.indexOf(id)}:</b> {id}
              </li>
            );
          })}
        </List>
      );
    }
  };
  const BookingReferences = () => {
    if (references.length === 1) {
      return (
        <Col id="bookingReferenceId">
          Booking reference: {references[0].reference}
        </Col>
      );
    } else {
      return (
        <List>
          {references.map((bookingReference, index) => {
            return (
              <li id={"bookingReferenceIdNo-" + index}>
                <b>Booking reference {index}:</b> {bookingReference.reference}
              </li>
            );
          })}
        </List>
      );
    }
  };

  const BookingText = () => {
    if (bookingIds.length === 1) {
      return (
        <div>
          Your booking was successfully processed and all information will be
          sent by email.
        </div>
      );
    } else {
      return (
        <div>
          Your booking is a multi-booking with {bookingIds.length} berths.{" "}
          <br></br>
          All information will be sent by email.
        </div>
      );
    }
  };

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner>Loading</Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col md="12">
          <h1 align="center">Booking Confirmed</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <BookingText />
        </Col>
      </Row>
      <div style={{ display: "none" }}>
        <Row>
          <BookingIds />
        </Row>
      </div>
      <Row>
        <BookingReferences />
      </Row>
      {/* <Row>
        <Col>
          <Button
            id="bookingInformationButtonId"
            size="sm"
            color="primary"
            tag={Link}
            to={"/bookings/" + bookingIds[0]}
          >
            View booking information
          </Button>
        </Col>
      </Row> */}
    </Container>
  );
};

export default BookingConfirmation;

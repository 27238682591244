import React, { useEffect, useState } from "react";
import "./App.css";
import AppNavbar from "./AppNavbar";
import HarbourImagesList from "./HarbourImagesList";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import {
  Button,
  Container,
  Row,
  Col,
  List,
  Card,
  CardBody,
  CardHeader,
  Label,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { HBApi } from "./HBApi";

const About = () => {
  const [cookies] = useCookies(["XSRF-TOKEN"]); // <.>

  return (
    <Container fluid className="withBgImage">
      <Container fluid>
        <Row>
          <AppNavbar />
        </Row>
        <Row>
          <Col md="2" />
          <Col md="8">
            <div className="home-text-on-image">
              HarbourMe - The premium booking experience
            </div>
            <div className="home-text-on-image-2">Available from 2024</div>
          </Col>
          <Col md="2" />
        </Row>

        {/* </Container>
      <Container fluid> */}
        <Row>
          <Col></Col>
          <Col md="6">
            <Card>
              <CardHeader className="fw-bold p-4 my-1 fs-3">
                Highlights
              </CardHeader>
              <CardBody>
                <List className="p-2 my-2">
                  <li className="fw-bold p-2 my-1">
                    Easy booking with unprecedented functionality
                  </li>
                  <li className="fw-bold p-2 my-1">
                    Multi-bookings, waiting list, booking optimization and many
                    more utilities waiting for you as a guest
                  </li>
                  <li className="fw-bold p-2 my-1">
                    Frictionless onboarding for Marinas and yacht clubs and
                    their members
                  </li>
                  <li className="fw-bold p-2 my-1">
                    High engagement features for yacht club members
                  </li>
                  <li className="fw-bold p-2 my-1">
                    Administer your berth in seconds
                  </li>
                  <li className="fw-bold p-2 my-1">
                    Opt in service design with no lock-in or subscription fees
                  </li>
                  <li className="fw-bold p-2 my-1">
                    Flexible financial operational model, you decide the details
                  </li>
                </List>
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col>
            <div className="text-on-image-medium">
              Enroll your marina today by contacting info@harbourme.com
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <CookieConsent
        style={{ background: "#556577" }}
        buttonStyle={{
          background: "#FEFEFE",
          color: "#4e503b",
          fontSize: "13px",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Container>
  );
};

export default About;

import React, { useState } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";

function AppNavbar() {
  const auth = useAuth();
  const user = auth.getUser();
  const [isOpen, setIsOpen] = useState(false);
  console.log("AppNavbar with user: " + JSON.stringify(user));
  return (
    <Navbar dark className="my-2" expand="md">
      <NavbarBrand className="navbarbrand" href="/">
        <img
          alt="logo"
          src="/favicon.ico"
          style={{
            height: 40,
            width: 40,
          }}
        />
        HarbourMe
      </NavbarBrand>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav
          id="the-nav-id"
          className="justify-content-end"
          style={{ width: "100%" }}
          navbar
        >
          <NavItem className="navbar-default">
            <NavLink tag={Link} to="/">
              Search
            </NavLink>
          </NavItem>
          {user && auth.hasRole("ADMIN") && (
            <NavItem className="navbar-default">
              <NavLink id="feeSupportId" tag={Link} to="/admin/feesupport">
                BerthStatus
              </NavLink>
            </NavItem>
          )}
          {user && auth.hasRole("USER") && (
            <NavItem className="navbar-default">
              <NavLink id="profileId" tag={Link} to="/mypage">
                Profile
              </NavLink>
            </NavItem>
          )}

          {!user && (
            <NavItem className="navbar-default">
              <NavLink id="signInId" tag={Link} to="/signin">
                Sign In
              </NavLink>
            </NavItem>
          )}
          {!user && (
            <NavItem className="navbar-default">
              <NavLink id="signUpId" tag={Link} to="/signup">
                Sign Up
              </NavLink>
            </NavItem>
          )}
          {user && (
            <NavItem className="navbar-default">
              <NavLink id="signOutId" tag={Link} to="/signout">
                Sign Out
              </NavLink>
            </NavItem>
          )}
          <NavItem className="navbar-default">
            <NavLink tag={Link} to="/about">
              About
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default AppNavbar;

import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Toast,
  ToastHeader,
  ToastBody,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import AppNavbarLight from "./AppNavbarLight";
import { useAuth } from "./AuthContext";
import { HBApi } from "./HBApi";

const VerifyEmail = () => {
  const { id } = useParams();
  const [verifyResult, setVerifyResult] = useState("");
  useEffect(() => {
    console.log(`Enter use effect with id : ${id} `);

    HBApi.verifyRegistrationEmail(id)
      .then((response) => {
        console.log(`Received response : ${JSON.stringify(response)}`);
        if (response.ok) {
          console.log("Response is ok");
          return response.body;
        } else {
          console.log(`Response is not ok`);
          return Promise.reject(response);
        }
      })
      .then((data) => {
        console.log("Received string response : " + JSON.stringify(data));
        setVerifyResult(
          "Successfully verified email address. Your account is ready to use."
        );
      })
      .catch((errorResponse) => {
        console.log("errorResponse " + errorResponse.status);
        if (errorResponse.status === 417) {
          setVerifyResult(
            "The verification link has expired. Please sign up again."
          );
        } else if (errorResponse.status === 404) {
          setVerifyResult("No registration with the provided code was found.");
        } else if (errorResponse.status === 409) {
          setVerifyResult(
            "The verification link has already been processed. The account should be activated."
          );
        } else if (errorResponse.status === 500) {
          setVerifyResult("An error ocurred");
        }
      });
  }, []);

  console.log("Enter verify email");

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>Email verification</b>
                </ToastHeader>
                <ToastBody>
                  {verifyResult}
                  <hr />
                </ToastBody>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default VerifyEmail;

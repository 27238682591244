import { useAuth } from "../AuthContext";
import {
  Card,
  CardTitle,
  CardText,
  Label,
  Input,
  Form,
  Button,
  Badge,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import { MapContainer, TileLayer, Circle, Popup, Tooltip } from "react-leaflet";
import React, { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import HarbourStatusMap from "./HarbourStatusMap";
import { HBApi } from "../HBApi";
import { BerthFunctions } from "../BerthFunctions";

const FeeSupport = () => {
  const auth = useAuth();
  const [harbour, setHarbour] = useState();
  const [loading, setLoading] = useState(true);
  const [berths, setBerths] = useState([]);
  const [manualCoords, setManualCoords] = useState();
  const onError = (event) => {};
  const onSuccess = (event) => {
    try {
      console.log(
        `onSuccess : ${JSON.stringify(event.coords.latitude)}, ${JSON.stringify(
          event.coords.longitude
        )}`
      );
    } catch (onError) {
      console.log(`Caught error ${JSON.stringify(onError)}`);
    }
    setManualCoords([event.coords.latitude, event.coords.longitude]);
  };
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      watchPosition: false,
      userDecisionTimeout: 5000,
      suppressLocationOnMount: false,
      isOptimisticGeolocationEnabled: true,
      watchLocationPermissionChange: false,
      onError,
      onSuccess,
    });

  const handleSubmit = async (event) => {
    event.preventDefault();
    getPosition();
  };

  const computeMapCoords = () => {
    if (BerthFunctions.near(coords, berths[0], 1000)) {
      return manualCoords;
    } else {
      return [harbour.latitude, harbour.longitude];
    }
  };
  const BerthStatusSymbol = ({ berth }) => {
    console.log(`BerthStatusSymbol : ${JSON.stringify(berth)}`);
    if (berth.enabled === false) {
      return <Badge color="danger">Unavailable</Badge>;
    } else if (berth.available === true) {
      return <Badge color="success">Available</Badge>;
    } else {
      return <Badge color="secondary">Booked</Badge>;
    }
  };

  const BoatText = ({ berth }) => {
    if (berth.boat) {
      return (
        <div>
          {berth.electricity ? "(E)" : " "} {berth.cancellable ? "(C)" : " "}{" "}
          {berth.boat?.boatModel} , {berth.boat?.boatName}
        </div>
      );
    } else {
      return "";
    }
  };
  const NearbyBerths = () => {
    console.log(`Compute near berths`);
    return berths
      .filter((b) => BerthFunctions.near(coords, b, 30))
      .toSorted((l, r) => {
        if (
          BerthFunctions.distance(coords, l) <
          BerthFunctions.distance(coords, r)
        ) {
          return -1;
        } else if (
          BerthFunctions.distance(coords, l) >
          BerthFunctions.distance(coords, r)
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((nearBerth) => {
        return (
          <Row>
            <Col>
              <b> {nearBerth.label}</b> <BerthStatusSymbol berth={nearBerth} />
            </Col>

            {nearBerth.boat ? (
              <Col>
                {nearBerth.electricity ? "(E)" : " "}{" "}
                {nearBerth.boat?.boatModel} , {nearBerth.boat?.boatName}
              </Col>
            ) : (
              <Col></Col>
            )}
          </Row>
        );
      });
  };

  const MyHarbourStatusMap = ({ myCoords, berths }) => {
    console.log(
      `HarbourStatusMap with berths ${berths.length}, latitude:${myCoords[0]}, longitude: ${myCoords[1]}`
    );

    const BerthMarkers = () => {
      return berths.map((berth) => {
        var radius = 2;
        var color = "";
        if (!berth.enabled) {
          color = "red";
        } else if (berth.available) {
          color = "green";
        } else {
          color = "gray";
        }

        return (
          <Circle
            key={berth.id}
            center={[berth.latitude, berth.longitude]}
            radius={radius}
            color={color}
          >
            <Tooltip>
              {berth.label} <BoatText berth={berth} />
            </Tooltip>
          </Circle>
        );
      });
    };

    return (
      <MapContainer center={myCoords} zoom={18} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Circle key="mapCenterId" center={myCoords} radius="1" color="yellow">
          <Tooltip>Your position</Tooltip>
        </Circle>
        <BerthMarkers />
      </MapContainer>
    );
  };

  useEffect(() => {
    setLoading(true);
    const now = new Date();
    HBApi.getAdminInfo()
      .then((response) => response.json())
      .then((adminInfoJson) => {
        setHarbour(adminInfoJson.harbour);
        return HBApi.getAdminBerthStatusForFeeSupport(
          adminInfoJson.harbour.id,
          now.toISOString()
        );
      })
      .then((response) => response.json())
      .then((berthsJson) => {
        setBerths(berthsJson);
      })
      .then(() => setLoading(false));
  }, []);

  if (!isGeolocationAvailable) {
    return <div>Your browser does not support Geolocation</div>;
  }
  if (!isGeolocationEnabled) {
    return <div>Geolocation is not enabled</div>;
  }

  if (loading) {
    return <Spinner />;
  }

  if (!manualCoords) {
    return <div>Getting location</div>;
  }

  if (auth.hasRole("ADMIN"))
    return (
      <Container>
        <NearbyBerths />
        <Row>
          <Col>
            <Button color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <MyHarbourStatusMap
              myCoords={computeMapCoords()}
              berths={berths}
            ></MyHarbourStatusMap>
          </Col>
        </Row>
      </Container>
    );
};

export default FeeSupport;

import React, { useState } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";

const AppNavbarLight = () => {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const user = auth.getUser();

  return (
    <Navbar light className="my-2" expand="md">
      <NavbarBrand className="navbarbrand" href="/">
        <img
          alt="logo"
          src="/favicon.ico"
          style={{
            height: 40,
            width: 40,
          }}
        />
        HarbourMe
      </NavbarBrand>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="justify-content-end" style={{ width: "100%" }} navbar>
          <NavItem className="navbar-default">
            <NavLink tag={Link} to="/">
              Search
            </NavLink>
          </NavItem>
          {user && auth.hasRole("ADMIN") && (
            <NavItem className="navbar-default">
              <NavLink id="feeSupportId" tag={Link} to="/admin/feesupport">
                BerthStatus
              </NavLink>
            </NavItem>
          )}
          {user && auth.hasRole("USER") && (
            <NavItem className="navbar-default">
              <NavLink id="profileId" tag={Link} to="/mypage">
                Profile
              </NavLink>
            </NavItem>
          )}

          {!user && (
            <NavItem className="navbar-default">
              <NavLink id="signInId" tag={Link} to="/signin">
                Sign In
              </NavLink>
            </NavItem>
          )}
          {!user && (
            <NavItem className="navbar-default">
              <NavLink id="signUpId" tag={Link} to="/signup">
                Sign Up
              </NavLink>
            </NavItem>
          )}
          {user && (
            <NavItem className="navbar-default">
              <NavLink id="signOutId" tag={Link} to="/signout">
                Sign Out
              </NavLink>
            </NavItem>
          )}

          <NavItem className="navbar-default">
            <NavLink tag={Link} to="/about">
              About
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppNavbarLight;

import "leaflet/dist/leaflet.css";
import lscache from "lscache";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardText,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
  Spinner,
  List,
} from "reactstrap";
import HarbourMap from "../HarbourMap";
import BerthInputEmbedded from "./BerthInputEmbedded";
import Trail from "../Trail";
import { HBApi } from "../HBApi";

import "../App.css";
import { BerthFunctions } from "../BerthFunctions";

const br = "8px";

const boatDimensionsValid = (length, width, depth) => {
  var isValidDim = false;
  if (isValid(length) && isValid(width) && isValid(depth)) isValidDim = true;
  return isValidDim;
};

const berthsCompatibleIds = (berthsToEvaluate, length, width, depth) => {
  let compatible = berthsToEvaluate
    .filter(function (berth) {
      return berthCompatible(berth, length, width, depth);
    })
    .map((b) => b.id);
  return compatible;
};

const berthsCompatible = (berthsToEvaluate, length, width, depth) => {
  let compatible = berthsToEvaluate.filter(function (berth) {
    return berthCompatible(berth, length, width, depth);
  });
  console.log(
    "Found " +
      compatible.length +
      " berths compatible with dimensions (l, w, d): " +
      length +
      ", " +
      width +
      ", " +
      depth
  );
  return compatible.length !== 0;
};

// Return true iff all measurements are valid and berth is compatible
const berthCompatible = (berth, length, width, depth) => {
  var rv = true;
  if (
    !isValid(length) ||
    !isValid(width) ||
    !isValid(depth) ||
    (isValid(length) && berth.maxLength < length) ||
    (isValid(length) && berth.minLength > length) ||
    (isValid(width) && berth.maxWidth < width) ||
    (isValid(width) && berth.minWidth > width) ||
    (isValid(depth) && berth.maxDepth < depth) ||
    (isValid(depth) && berth.minDepth > depth)
  ) {
    rv = false;
  }
  return rv;
};
const isValid = (value) => {
  var iv = true;
  if (!value) iv = false;
  return iv;
};

const HarbourEmbedded = () => {
  const { id } = useParams();

  //console.log("Harbour with id:" + id);
  const [berths, setBerths] = useState([]);
  const [berthSearchResult, setBerthSearchResult] = useState([]);
  const [trailSearchResult, setTrailSearchResult] = useState([]);
  const [harbour, setHarbour] = useState({});
  const [coords, setCoords] = useState([0, 0]);
  const [loading, setLoading] = useState(true);
  const [selectedBerth, setSelectedBerth] = useState();
  const [arrival, setArrival] = useState(
    lscache.get("harbourSearchObject")?.arrival
      ? new Date(Date.parse(lscache.get("harbourSearchObject").arrival))
      : null
  );
  const [departure, setDeparture] = useState(
    lscache.get("harbourSearchObject")?.departure
      ? new Date(Date.parse(lscache.get("harbourSearchObject").departure))
      : null
  );
  const [searchCount, setSearchCount] = useState(0);

  const berthsCompatibleUsingState = (length, width, depth) => {
    return berthsCompatible(berths, length, width, depth);
  };

  // We search again if the dates have changed, or if the dimensions have changed and the change in dimension
  // leads to a different subset of compatible berths
  function shouldSearchAgain(lastSearchObject, newSearchObject) {
    console.log(
      `Enter shouldSearchAgain with lastSearchObject: ${JSON.stringify(
        lastSearchObject
      )} and newSearchObject: ${JSON.stringify(newSearchObject)}`
    );
    if (!newSearchObject.departure) {
      return false;
    } else {
      return true;
    }

    if (JSON.stringify(newSearchObject) === JSON.stringify(lastSearchObject)) {
      console.log(
        "Stringify comparison of new and last search object: they are identical, should not search again"
      );
      return false;
    } else if (!lastSearchObject.departure && newSearchObject.departure) {
      // Happens during the first search when boat information was cached
      return true;
    } else if (
      lastSearchObject.departure &&
      lastSearchObject.arrival &&
      (new Date(Date.parse(newSearchObject.departure)).getTime() !==
        new Date(Date.parse(lastSearchObject.departure)).getTime() ||
        new Date(Date.parse(newSearchObject.arrival)).getTime() !==
          new Date(Date.parse(lastSearchObject.arrival)).getTime())
    ) {
      console.log("Dates comparison evaluates to differing values");
      return true;
    } else if (berths.length > 0) {
      let berthsCompatibleIdsLast = berthsCompatibleIds(
        berths,
        lastSearchObject.length,
        lastSearchObject.width,
        lastSearchObject.depth
      );
      let berthsCompatibleNew = berthsCompatibleIds(
        berths,
        newSearchObject.length,
        newSearchObject.width,
        newSearchObject.depth
      );

      let rv =
        JSON.stringify(berthsCompatibleIdsLast) !==
        JSON.stringify(berthsCompatibleNew);
      console.log(
        `Compared berthsCompatible identical with last/new search object ${!rv}, last/new search:  ${
          berthsCompatibleIdsLast.length
        }/${berthsCompatibleNew.length}`
      );
      return rv;
    }
  }

  const [searchObject, dispatch] = useReducer(
    formReducer,
    lscache.get("harbourSearchObject") ?? {}
  );
  function formReducer(state, action) {
    console.log(
      `Enter formReducer: state:${JSON.stringify(
        state
      )}, and action ${JSON.stringify(action)}`
    );
    var newSearchObject = { ...state };
    switch (action.type) {
      case "dates":
        newSearchObject = {
          ...newSearchObject,
          arrival: action.payload.arrival,
          departure: action.payload.departure,
        };

        break;
      case "dimension":
        newSearchObject = {
          ...newSearchObject,
          [action.payload.name]: action.payload.value,
        };
        break;
      case "dimensionFieldComplete":
        break;
      case "searchObject":
        newSearchObject = action.payload;
        break;
      default:
    }
    console.log(`newSearchObject is ${JSON.stringify(newSearchObject)}`);
    lscache.set("harbourSearchObject", newSearchObject, 3);
    if (
      boatDimensionsValid(
        newSearchObject.length,
        newSearchObject.width,
        newSearchObject.depth
      )
    ) {
      if (
        berthsCompatibleUsingState(
          newSearchObject.length,
          newSearchObject.width,
          newSearchObject.depth
        )
      ) {
        console.log("There are berths compatible with dimensions.");

        if (shouldSearchAgain(state, newSearchObject)) {
          console.log(
            "Should search again is true, will perform new search with: " +
              JSON.stringify(action)
          );
          setSearchCount((count) => count + 1);
        } else {
          console.log(
            `No need to search again, setting search object ${JSON.stringify(
              newSearchObject
            )}`
          );
          if (!newSearchObject.departure && state.departure) {
            return state;
          } else {
            return newSearchObject;
          }
        }
      } else {
        console.log("No compatible berths, deleting berth search result");
        setBerthSearchResult([]);
      }
      return newSearchObject;
    } else {
      console.log(
        `Returning at last return in dispatch with ${JSON.stringify(
          newSearchObject
        )}`
      );
      return newSearchObject;
    }
  }

  var today = new Date();
  var maxDate = new Date(today.getTime() + 150 * 86400000);
  var imagepath = "/images/harbours/" + harbour.image;

  useEffect(() => {
    console.log("Second use effect");
    if (searchCount > 0) {
      processSearchObject(searchObject);
    }
  }, [searchCount]);

  useEffect(() => {
    console.log(
      `Enter useEffect() , searchObject is ${JSON.stringify(searchObject)}`
    );
    setLoading(true);
    let cachedSearchObject = searchObject;
    let cachedBoatInformation = lscache.get("boatinformation");
    if (
      (!cachedSearchObject || Object.keys(cachedSearchObject).length === 0) &&
      cachedBoatInformation
    ) {
      cachedSearchObject = {
        length: cachedBoatInformation[0].length,
        width: cachedBoatInformation[0].width,
        depth: cachedBoatInformation[0].depth,
      };
    }
    if (cachedSearchObject) {
      console.log(
        "Setting search object to already cached data, or copied from cached boat information: " +
          JSON.stringify(cachedSearchObject)
      );
      dispatch({ type: "searchObject", payload: cachedSearchObject });
    }

    HBApi.getBerthsForHarbour(id)
      .then((response) => response.json())
      .then((data) => {
        setBerths(data);
        console.log(
          "Received " +
            data.length +
            " berths for harbour " +
            id +
            " in useEffect"
        );
        if (cachedSearchObject) {
          maybeProcessSearch(data, cachedSearchObject);
        }
      })
      .then(() => {
        HBApi.getHarbourById(id)
          .then((response) => response.json())
          .then((data) => {
            setHarbour(data);
            setCoords([data.latitude, data.longitude]);
            console.log(
              "Received harbour " +
                data.name +
                " with coordinates: " +
                coords +
                " in useEffect"
            );
            setLoading(false);
          });
      });
  }, []);

  const maybeProcessSearch = (berths, cachedSearchObject) => {
    console.log(
      `Enter maybeProcessSearch with ${berths.length} and ${JSON.stringify(
        cachedSearchObject
      )} `
    );
    if (
      boatDimensionsValid(
        cachedSearchObject.length,
        cachedSearchObject.width,
        cachedSearchObject.depth
      ) &&
      berthsCompatible(
        berths,
        cachedSearchObject.length,
        cachedSearchObject.width,
        cachedSearchObject.depth
      )
    ) {
      if (cachedSearchObject.arrival && cachedSearchObject.departure) {
        processSearchObject(cachedSearchObject);
      }
    }
  };

  if (loading) {
    console.log("Loading true, display spinner");
    return (
      <Container>
        <Row>
          <Spinner>Loading</Spinner>
        </Row>
      </Container>
    );
  }

  const shouldDisplayDPF = () => {
    return (
      boatDimensionsValid(
        searchObject.length,
        searchObject.width,
        searchObject.depth
      ) &&
      berthsCompatible(
        berths,
        searchObject.length,
        searchObject.width,
        searchObject.depth
      )
    );
  };

  const datePicker = () => {
    return (
      <div
        id="datepickerDiv"
        style={{ display: shouldDisplayDPF() ? "block" : "none" }}
      >
        <DatePicker
          minDate={today}
          maxDate={maxDate}
          startDate={arrival}
          endDate={departure}
          selectsRange={true}
          inline
          onChange={onDateChange}
          selectsDisabledDaysInRange={false}
          excludeDates={calcExcludedDays}
        />
      </div>
    );
  };
  const calendarMessage = () => {
    if (
      !boatDimensionsValid(
        searchObject.length,
        searchObject.width,
        searchObject.depth
      )
    ) {
      return (
        <div id="calendarMessage">Please enter boat dimensions in metres.</div>
      );
    } else if (
      !berthsCompatibleUsingState(
        searchObject.length,
        searchObject.width,
        searchObject.depth
      )
    ) {
      return (
        <div id="calendarMessage">
          No berths available for your boat dimensions.
        </div>
      );
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log(
      "Handle change with name: " +
        name +
        " and value: " +
        value +
        ", bearthSearchResult is: " +
        berthSearchResult.length
    );

    dispatch({ type: "dimension", payload: { name: [name], value: value } });
  };
  const handleBlur = async (event) => {
    const { name, value } = event.target;
    console.log(
      "Handle blur with name: " +
        name +
        " and value: " +
        value +
        ", bearthSearchResult is: " +
        berthSearchResult.length
    );

    // dispatch({
    //   type: "dimensionFieldComplete",
    //   payload: { name: [name], value: value },
    // });
  };

  function processSearchObject(newSearchObject) {
    console.log(
      "Enter processSearchObject with newSearchOject: " +
        JSON.stringify(newSearchObject)
    );
    if (newSearchObject.departure !== null) {
      HBApi.searchBerthByHarbourAndCriteria(
        id,
        newSearchObject.arrival,
        newSearchObject.departure,
        newSearchObject.length,
        newSearchObject.width,
        newSearchObject.depth
      )
        .then((response) => response.json())
        .then((data) => {
          var availableBerths = data.filter((b) => b.available === true);
          var nonAvailableBerths = data.filter((b) => b.available === false);
          if (availableBerths.length === 0) {
            console.log(
              "Received only busy berths and " +
                nonAvailableBerths.length +
                " unavailable berths, there might be a trail"
            );
            HBApi.getTrails(id, newSearchObject).then((trailsResult) => {
              if (trailsResult.length === 0) {
                console.log(
                  "No trail found, non available berth count is: " + data.length
                );
                setTrailSearchResult([]);
                setBerthSearchResult(data);
              } else {
                console.log(
                  "Trail of length " + trailsResult.length + " found"
                );
                setTrailSearchResult(trailsResult);
                setBerthSearchResult([]);
              }
            });
          } else {
            console.log(
              "Received " +
                data.length +
                " berths for harbour " +
                id +
                " in processSearchObject  "
            );
            setBerthSearchResult(data);
            setTrailSearchResult([]);
          }
        });
    }
  }
  const onDateChange = (dates) => {
    const [start, end] = dates;
    if (end && start.getTime() === end.getTime()) {
      console.log("Start date = end date");
      return;
    }
    if (end) {
      console.log("End date exists in onDateChange");
      dispatch({ type: "dates", payload: { arrival: start, departure: end } });
    }
    setArrival(start);
    setDeparture(end);
  };

  const calcExcludedDays = [];
  const modSearchObject = () => {
    let arrivalHour = new Date(Date.parse(searchObject.arrival));
    //console.log(`BerthOrTrail arrivalHour ${JSON.stringify(arrivalHour)}`);
    arrivalHour.setHours(12);

    let departureHour = new Date(Date.parse(searchObject.departure));
    departureHour.setHours(12);
    return {
      ...searchObject,
      arrival: arrivalHour,
      departure: departureHour,
    };
  };
  const BerthOrTrail = () => {
    if (berthSearchResult.length > 0) {
      return (
        <BerthInputEmbedded
          selectedBerth={selectedBerth}
          setSelectedBerth={setSelectedBerth}
          berthSearchResult={berthSearchResult}
          harbour={harbour}
          criteria={modSearchObject()}
        />
      );
    } else if (trailSearchResult.length > 0) {
      return (
        <Trail
          trailSearchResult={trailSearchResult}
          harbour={harbour}
          criteria={modSearchObject()}
        />
      );
    } else {
      console.log(
        `Neither berthSearchResult nor trailSearchResult have length > 0`
      );
      return <div>No available berths for the selected period</div>;
    }
  };
  return (
    <Container>
      {/* <Row>
        <Col>
          <Label for="img0" tag="h1">
            {harbour.name}
          </Label>
          <img
            src={imagepath}
            id="harbourImageId"
            style={{ borderRadius: "10px" }}
          />
        </Col>
      </Row> */}
      <Row>
        <Col md="6">
          <Card>
            <CardTitle tag="h5">Booking instructions</CardTitle>
            <CardText>
              <List>
                <li>Enter the measurements of your boat</li>
                <li>Select arrival and departure for your stay</li>
                <li>Click proceed</li>
                <li>
                  You can optionally make the booking cancellable in the next
                  step
                </li>
              </List>
            </CardText>
          </Card>
        </Col>
        <Col md="6">
          <Form className="bg-light border">
            <Label size="lg">Boat dimensions</Label>
            <Row>
              <Col>
                <FormGroup floating>
                  <Input
                    style={{ borderRadius: br }}
                    placeholder="Length"
                    value={searchObject.length}
                    type="number"
                    name="length"
                    id="length"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="length"
                  />
                  <Label for="length" size="sm">
                    Length
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup floating>
                  <Input
                    style={{ borderRadius: br }}
                    placeholder="Width"
                    value={searchObject.width}
                    type="number"
                    name="width"
                    id="width"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="width"
                  />
                  <Label for="width" size="sm">
                    Width
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup floating>
                  <Input
                    style={{ borderRadius: br }}
                    placeholder="Depth"
                    value={searchObject.depth}
                    type="number"
                    name="depth"
                    id="depth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="depth"
                  />
                  <Label for="depth" size="sm">
                    Depth
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="arrival">Arrival/Departure</Label>
                  {datePicker()}
                  {calendarMessage()}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <HarbourMap
            coords={coords}
            selectedBerth={selectedBerth}
            setSelectedBerth={setSelectedBerth}
            berthSearchResult={berthSearchResult}
            trailSearchResult={trailSearchResult}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <BerthOrTrail />
        </Col>
      </Row>
    </Container>
  );
};

export default HarbourEmbedded;

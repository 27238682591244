import "leaflet/dist/leaflet.css";
import lscache from "lscache";
import DatePicker from "react-datepicker";
import CalendarContainer from "react-datepicker";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { MapContainer, TileLayer, Marker, Circle,Popup, useMap } from "react-leaflet";
import * as L from "leaflet";
import React, { useEffect, useState} from 'react';
import { Link, useParams} from 'react-router-dom';
import { Label, Input, InputGroup, InputGroupText, Form, FormGroup, Row, Col, Button, ButtonGroup, Container, Table } from 'reactstrap';
import HarbourMap from './HarbourMap';
import './App.css';

const Footer = () => {

return <div className="App-header">
www.harbourme.com <br/>
info@harbourme.com
</div>
};

export default Footer;
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Row,
  Col,
  Button,
  Container,
  FormGroup,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import AppNavbarLight from "./AppNavbarLight";
import { useAuth } from "./AuthContext";
import { HBApi } from "./HBApi";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [error404, setError404] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    HBApi.forgotPassword(email)
      .then((response) => {
        if (response.ok) {
          console.log("Setting forgot success true");
          setForgotSuccess(true);
        }
        return Promise.reject(response);
      })
      .catch((errorResponse) => {
        if (errorResponse.status === 404) {
          setError404(true);
        }
        console.log("errorResponse" + errorResponse.status);
      });
  };

  if (forgotSuccess) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>Password reset link sent</b>
                </ToastHeader>
                <ToastBody>
                  <hr />
                  <ul>
                    <li>Please follow the link in the email we have sent!</li>
                    <li>The password reset link expires in five minutes.</li>
                  </ul>
                </ToastBody>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md="1"></Col>
          <Col>
            <Form
              className="bg-light border"
              style={{ "border-radius": "15px" }}
              onSubmit={handleSubmit}
            >
              <h2 align="center">Forgotten password</h2>
              <FormGroup row>
                <Label for="email">Enter email</Label>
                <InputGroup>
                  {error404 ? (
                    <Input
                      style={{ "border-radius": "8px" }}
                      id="emailId"
                      name="email"
                      type="text"
                      invalid
                      onChange={handleChange}
                    />
                  ) : (
                    <Input
                      style={{ "border-radius": "8px" }}
                      id="emailId"
                      name="email"
                      type="text"
                      onChange={handleChange}
                    />
                  )}
                  <FormFeedback>
                    No account with this email address
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <Button size="md" color="primary" id="submitId" type="submit">
                    Reset password
                  </Button>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>
          <Col md="1"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ForgotPassword;

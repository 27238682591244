import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useEffect, useState } from "react";
import lscache from "lscache";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Label,
  Input,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Row,
  Col,
  Button,
  Container,
  InputGroup,
  Spinner,
  List,
} from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";
import { CountryDropdown } from "react-country-region-selector";
import { useAuth } from "./AuthContext";
import { HBApi } from "./HBApi";
const br = "8px";

const BookingDetails = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [bookingDryRun, setBookingDryRun] = useState([]);
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  let priorityCountries = ["Sweden", "Denmark"];
  const currency = () => bookingDryRun[0].currency;
  const cancellableDaysCount = () =>
    bookingDryRun
      .map((dr) => dr.cancellableDaysCount)
      .reduce((l, r) => l + r, 0);
  // console.log(
  //   `Enter BookingDetails with location.state: ${JSON.stringify(
  //     location.state
  //   )}`
  // );
  let harbour = location.state.harbour;

  // In case of cached criteria, the arrival/departure format is 2023-10-01T22:00:00.000Z - will not be a date
  let criteria = {
    ...location.state.criteria,
    arrival: new Date(location.state.criteria.arrival),
    departure: new Date(location.state.criteria.departure),
  };
  let segments = location.state.trail;
  if (!segments || segments.length === 0) {
    segments = [
      {
        berthId: location.state.selectedBerth.id,
        label: location.state.selectedBerth.label,
        from: criteria.arrival,
        to: criteria.departure,
        fixationTypeLabel: location.state.selectedBerth.fixationTypeLabel,
      },
    ];
  }
  // console.log(
  //   `BookingDetails constructed segments: ${JSON.stringify(segments)}`
  // );
  let imagePath = "/images/harbours/" + harbour.image;
  const dayCount = () =>
    bookingDryRun.map((dr) => dr.days.length).reduce((l, r) => l + r, 0);

  let cachedBoatInformation = lscache.get("boatinformation")
    ? lscache.get("boatinformation")
    : [];
  // console.log(
  //   "Enter booking details with prefilled boat information: " +
  //     JSON.stringify(cachedBoatInformation)
  // );
  let bd = auth.userIsAuthenticated()
    ? {
        electricity: false,
        cancellation: false,
        boattype: cachedBoatInformation[0]
          ? cachedBoatInformation[0].boatType
          : "SAIL",
        boatmodel: cachedBoatInformation[0]?.boatModel,
        boatname: cachedBoatInformation[0]?.boatName,
        email: auth.getUser().email,
        firstName: auth.getUser().firstName,
        lastName: auth.getUser().lastName,
        phone: auth.getUser().phone,
        country: auth.getUser().country,
      }
    : { electricity: false, cancellation: false, boattype: "SAIL" };
  const [bookingDetails, setBookingDetails] = useState(bd);

  // console.log(
  //   "Enter BookingDetails with arrival: " +
  //     criteria.arrival +
  //     " , departure: " +
  //     criteria.departure
  // );
  function dateString(date) {
    return date.toLocaleDateString("en-us", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  function onlyDateString(dateTime) {
    return new Date(dateTime).toLocaleDateString("en-us", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  useEffect(() => {
    console.log("Enter useEffect, will fetch dryruns");
    async function fetchDryRun() {
      const dryRunResult = await HBApi.bookingDryRun(segments, criteria);
      console.log(
        `Fetched dryRunResult in useEffect  ${JSON.stringify(dryRunResult)}`
      );
      setBookingDryRun(dryRunResult);
    }

    fetchDryRun().then((result) => {
      setLoading(false);
    });
  }, []);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log(
      "Handle change with name: " +
        name +
        " and value: " +
        value +
        " old object:" +
        JSON.stringify(bookingDetails)
    );
    setBookingDetails({ ...bookingDetails, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(
      "Clicked Proceed and booking details is:" +
        JSON.stringify({ segments, bookingDetails })
    );
    const boatDetails = {
      boatType: bookingDetails.boattype,
      boatModel: bookingDetails.boatmodel,
      boatName: bookingDetails.boatname,
      length: criteria.length,
      width: criteria.width,
      depth: criteria.depth,
    };

    HBApi.book(segments, bookingDetails, boatDetails)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((json) => {
        console.log(
          "Received booking confirmation from wrapper csrf function: " +
            JSON.stringify(json)
        );
        navigate("/bookingconfirmation", { state: { bookingIds: json.ids } });
      })
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 409) {
          console.log(
            "409 response, this will happen as concurrent booking has been done"
          );

          navigate("/bookingconflict");
        }
      });
  };

  const handleSigninSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSigninSubmit");
    navigate("/signin", {
      state: {
        nextpage: "/bookingdetails",
        trail: segments,
        harbour: harbour,
        criteria: criteria,
      },
    });
  };

  const SignInButton = () => {
    if (!auth.userIsAuthenticated()) {
      return (
        <Button
          id="signInButton"
          size="md"
          color="primary"
          type="submit"
          onClick={handleSigninSubmit.bind(this)}
        >
          I have an account
        </Button>
      );
    }
  };

  const formReadyForValidation = () => {
    if (!bookingDetails.country || bookingDetails.country === "") return false;
    if (
      !bookingDetails.email ||
      !bookingDetails.firstName ||
      !bookingDetails.lastName ||
      !bookingDetails.phone ||
      !bookingDetails.crewCount ||
      !bookingDetails.boatmodel ||
      !bookingDetails.boatname
    )
      return false;
    return true;
  };

  const BookButton = () => {
    if (formReadyForValidation()) {
      return (
        <Button
          id="bookButtonId"
          size="lg"
          color="primary"
          type="submit"
          onClick={handleSubmit.bind(this)}
        >
          Proceed
        </Button>
      );
    } else {
      return (
        <Button disabled size="lg" color="primary" type="submit">
          Proceed
        </Button>
      );
    }
  };

  const totalPrice = () => {
    var rv = bookingDryRun
      .map((dr) => dr.totalBerthPrice)
      .reduce((l, r) => l + r, 0);
    if (bookingDetails.cancellation) {
      rv = bookingDryRun
        .map((dr) => dr.priceWithCancellation)
        .reduce((l, r) => l + r, 0);
    }
    if (bookingDetails.electricity) {
      rv += dayCount() * bookingDryRun[0].electricityPricePerDay;
    }

    return rv;
  };

  const BerthsOrTrailLabel = () => {
    if (segments.length === 1) {
      return <Label tag="h6">Berth: {segments[0].label}</Label>;
    } else {
      let berthsString = segments.map((s) => `${s.label}`).toString();
      let berthsStringTruncated = berthsString.substring(
        0,
        berthsString.length
      );
      return (
        <Label tag="h6">
          Trail of {segments.length} distinct bookings of berths:{" "}
          {berthsStringTruncated}
        </Label>
      );
    }
  };

  const AddonsPricePerDay = ({ day, dryRun }) => {
    console.log(
      `AddonsPricePerDay with day : ${JSON.stringify(day)} and cancellable: ${
        day.initialCancellableStatus
      }`
    );
    const c = day.initialCancellableStatus;
    switch (true) {
      case bookingDetails.cancellation === true &&
        bookingDetails.electricity === true &&
        c === 1:
        return `+ ${dryRun.cancellationPricePerDay} ${dryRun.currency} cancellation + ${dryRun.electricityPricePerDay} ${dryRun.currency} electricity`;
      case bookingDetails.cancellation === true &&
        bookingDetails.electricity === true &&
        c === 0:
        return `+ ${dryRun.electricityPricePerDay} ${dryRun.currency} electricity`;
      case bookingDetails.cancellation === false &&
        bookingDetails.electricity === true:
        return `+ ${dryRun.electricityPricePerDay} ${dryRun.currency} electricity`;
      case bookingDetails.cancellation === true &&
        bookingDetails.electricity === false &&
        c === 1:
        return `+ ${dryRun.cancellationPricePerDay} ${dryRun.currency} cancellation`;
      default:
        return "";
    }
  };
  function pricePerDayKey(day, segmentNo) {
    return `price-segment-${segmentNo}-day-${day.ordering}-id`;
  }
  const Day = ({ dryRun, segmentNo }) => {
    return dryRun.days.map((day) => (
      <li
        key={pricePerDayKey(day, segmentNo)}
        id={pricePerDayKey(day, segmentNo)}
      >
        {onlyDateString(day.from)} - {day.berthPrice + day.bookingPrice}{" "}
        {day.currency} <AddonsPricePerDay day={day} dryRun={dryRun} />
      </li>
    ));
  };
  const Segment = () => {
    return bookingDryRun.map((dr, index) => {
      return (
        <FormGroup row>
          <Col>
            <Label>
              <b>
                Berth {segments[index].label} , {dr.days.length}{" "}
                {dr.days.length === 1 && "night:"}
                {dr.days.length > 1 && "nights:"}
              </b>{" "}
            </Label>
            <List>
              <Day dryRun={dr} segmentNo={index} />
            </List>
          </Col>
          <Col></Col>
        </FormGroup>
      );
    });
  };

  if (loading) {
    return (
      <Container>
        <Row>
          <Col md="12">
            <AppNavbarLight />
          </Col>
        </Row>
        <Row>
          <Spinner>Loading</Spinner>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <Col md="12">
          <h1 align="center">Booking Details</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <img
            src={imagePath}
            alt="harbour image"
            style={{ borderRadius: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Form className="bg-light border">
          <h2 align="center">{harbour.name}</h2>
          <FormGroup row>
            <Col>
              <BerthsOrTrailLabel />
            </Col>
            <Col>
              <Label tag="h6">Arrival: {dateString(criteria.arrival)}</Label>
            </Col>
            <Col>
              <Label tag="h6">
                Departure: {dateString(criteria.departure)}
              </Label>
            </Col>
            <Col>
              <Label tag="h6">
                Fixation type: {segments[0].fixationTypeLabel}
              </Label>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <Label tag="h6">
                Dimensions (length/width/depth): {criteria.length}m/
                {criteria.width}m/{criteria.depth}m
              </Label>
            </Col>
          </FormGroup>

          <FormGroup row switch>
            <Col>
              <Label for="el">
                Electricity{" "}
                <b>
                  {bookingDryRun[0].electricityPricePerDay} {currency()}{" "}
                </b>{" "}
                per night
              </Label>
              <Input
                id="electricity"
                checked={bookingDetails.electricity}
                type="switch"
                onClick={() =>
                  setBookingDetails({
                    ...bookingDetails,
                    electricity: !bookingDetails.electricity,
                  })
                }
              />
            </Col>
          </FormGroup>
          {cancellableDaysCount() > 0 && (
            <FormGroup row switch>
              <Col>
                <Label for="cancellation">
                  Cancellation fee{" "}
                  <b>
                    {bookingDryRun[0].cancellationPricePerDay} {currency()}{" "}
                  </b>
                  per night
                </Label>
                <Input
                  id="cancellation"
                  checked={bookingDetails.cancellation}
                  type="switch"
                  role="cancellation"
                  onClick={() =>
                    setBookingDetails({
                      ...bookingDetails,
                      cancellation: !bookingDetails.cancellation,
                    })
                  }
                />
                <FormText>
                  (The cancellation fee is per night. You can cancel parts of
                  the stay if you wish to leave before the planned date of
                  departure.)
                </FormText>
              </Col>
            </FormGroup>
          )}
          {cancellableDaysCount() > 0 &&
            cancellableDaysCount() < dayCount() && (
              <FormGroup row>
                <Col>
                  <b>
                    Since the booking starts soon, not all days are cancellable
                  </b>{" "}
                  {bookingDetails.cancellation && "(see details below)"}
                </Col>
              </FormGroup>
            )}
          {cancellableDaysCount() === 0 && (
            <FormGroup row>
              <Col>
                <b>Note:</b> No days in this booking are cancellable because all
                start within 24 hours.
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Col>
              <Label>
                <b>
                  Total price for {dayCount()} {dayCount() === 1 && "night:"}
                  {dayCount() > 1 && "nights:"}{" "}
                </b>{" "}
              </Label>
              <FormText>
                {" "}
                Including the optional addons such as electricity.
              </FormText>
            </Col>
            <Col>
              <Label>
                <b>
                  {totalPrice()} {currency()}
                </b>
              </Label>
            </Col>
          </FormGroup>
          <Segment />
          <FormGroup row>
            <Col>
              <Label bssize="md" for="email">
                Email
              </Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.email}
                id="email"
                bssize="md"
                type="email"
                name="email"
                onChange={handleChange}
              />
              <SignInButton />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="firstName">First name</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.firstName}
                id="firstName"
                bssize="md"
                type="text"
                name="firstName"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Label for="lastName">Last name</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.lastName}
                id="lastName"
                bssize="md"
                type="text"
                name="lastName"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="phone">Phone number</Label>
              <PhoneInput
                style={{
                  borderRadius: br,
                  backgroundColor: "white",
                  height: "40px",
                }}
                id="phone"
                defaultCountry="SE"
                placeholder="Enter phone number"
                value={bookingDetails.phone}
                onChange={(val) =>
                  handleChange({ target: { name: "phone", value: val } })
                }
              />
            </Col>
            {/* <Col>
              <Label for="phone">Phone number</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.phone}
                id="phone"
                bssize="md"
                type="text"
                minLength="8"
                maxLength="30"
                name="phone"
                onChange={handleChange}
              />
            </Col> */}
            <Col>
              <Label for="country">Country </Label>
              <InputGroup>
                <CountryDropdown
                  style={{
                    borderRadius: br,
                    backgroundColor: "white",
                    height: "40px",
                  }}
                  id="country"
                  name="country"
                  value={bookingDetails.country}
                  priorityOptions={priorityCountries}
                  onChange={(val) =>
                    handleChange({ target: { name: "country", value: val } })
                  }
                />
                <FormFeedback>Please select country</FormFeedback>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="crewCount">Number of people</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.crewcount}
                id="crewCount"
                bssize="md"
                type="number"
                min="1"
                name="crewCount"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Label for="boattype">Boat type</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.boattype}
                id="boattype"
                bssize="md"
                type="select"
                name="boattype"
                onChange={handleChange}
              >
                <option key="sail" value="sail">
                  Sail
                </option>
                <option key="motor" value="motor">
                  Motor
                </option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="boatmodel">Boat model</Label>
              <Input
                style={{ borderRadius: br }}
                id="boatmodel"
                value={bookingDetails.boatmodel}
                bssize="md"
                type="text"
                name="boatmodel"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Label for="boatname">Boat name</Label>
              <Input
                style={{ borderRadius: br }}
                id="boatname"
                value={bookingDetails.boatname}
                bssize="md"
                type="text"
                name="boatname"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <BookButton />
          </FormGroup>
        </Form>
      </Row>
    </Container>
  );
};

export default BookingDetails;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Toast,
  ToastHeader,
  ToastBody,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import AppNavbarLight from "./AppNavbarLight";
import { useAuth } from "./AuthContext";
import { CountryDropdown } from "react-country-region-selector";
import { HBApi } from "./HBApi";

const SignUpSuccess = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  let location = useLocation();

  console.log("Enter signup success");

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>Sign up complete!</b>
                </ToastHeader>
                <ToastBody>
                  Verify your email account by clicking the verification link in
                  the email we have sent.
                  <hr />
                  <ul>
                    <li>
                      In case you do not receive the email, just sign up again
                      with the same email and follow the instructions.
                    </li>
                    <li>
                      Verify the email address within 24 hours, or the signup
                      will expire.
                    </li>
                  </ul>
                </ToastBody>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SignUpSuccess;

export const DateFunctions = {
  dateString,
  onlyDateString,
  stringToDateString,
  stringToTimeString,
};
function stringToDateString(dateString) {
  return DateFunctions.dateString(new Date(Date.parse(dateString)));
}
function dateString(date) {
  return date.toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
function onlyDateString(dateTime) {
  return new Date(dateTime).toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

function stringToTimeString(dateString) {
  return new Date(Date.parse(dateString)).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

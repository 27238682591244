import React, { createContext, useContext, useState, useEffect } from "react";
import lscache from "lscache";

const AuthContext = createContext();
const sessionTimeout = 30;
const userKey = "user";
function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(lscache.get(userKey));
    setUser(storedUser);
  }, []);

  const getUser = () => {
    if (lscache.get("user") === null) {
      return null;
    } else {
      return JSON.parse(lscache.get(userKey));
    }
  };
  const hasRole = (roleName) => {
    return getUser() ? getUser().roles?.includes(roleName) : false;
  };
  const userIsAuthenticated = () => {
    return lscache.get(userKey) !== null;
  };

  const userLogin = (user) => {
    lscache.set(userKey, JSON.stringify(user), sessionTimeout);
    setUser(user);
  };

  const userLogout = () => {
    lscache.remove(userKey);
    setUser(null);
  };

  const contextValue = {
    user,
    getUser,
    userIsAuthenticated,
    userLogin,
    userLogout,
    hasRole,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;

export function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider };

import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  List,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { HBApi } from "./HBApi";
import { HarbourFunctions } from "./HarbourFunctions";
import { DateFunctions } from "./DateFunctions";
import { useAuth } from "./AuthContext";
const FutureBookings = () => {
  const b = [
    { id: "1", label: "A1" },
    { id: "2", label: "A2" },
    { id: "3", label: "A3" },
  ];
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState("0");
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    HBApi.getUserFutureBookings()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        if (!data[0]) {
          //setLoading(false);
          return;
        } else {
          console.log("FutureBookings received data: " + JSON.stringify(data));
          setBookings(data);
        }
      })
      .then(() => setLoading(false))
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 401) {
          // If the server has restarted, this will happen
          console.log(
            "Unexpected 401 response, this will happen if the server has restarted"
          );
          auth.userLogout();
          navigate("/signin", { state: { nextpage: "/mypage/myberth" } });
        }
      });
  }, []);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  if (bookings.length === 0) {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h1 align="center">No future bookings</h1>
          </Col>
        </Row>
      </Container>
    );
  }

  const TotalPrice = ({ booking }) => {
    const ep = booking.electricity
      ? booking.electricityPrice * booking.days.length
      : 0;
    const cp = booking.cancellable
      ? booking.cancellablePrice *
        booking.days
          .filter((d) => d.initialCancellableStatus === 1)
          .map((d) => 1)
          .reduce((l, r) => l + r, 0)
      : 0;
    const bp = booking.days
      .map((b) => b.berthPrice + b.bookingPrice)
      .reduce((l, r) => l + r, 0);
    return (
      <div>
        <b>
          Total price {ep + cp + bp} {booking.days[0].currency}
        </b>
        {/* {booking.electricity && (
          <b>
            , electricity {ep} {booking.days[0].currency}
          </b>
        )}
        {booking.cancellable && (
          <b>
            , cancellation {cp} {booking.days[0].currency}
          </b>
        )} */}
      </div>
    );
  };
  const Days = ({ booking }) => {
    return booking.days.map((d, i) => (
      <li>
        Day {d.ordering + 1}: Berth price {d.berthPrice + d.bookingPrice}{" "}
        {d.currency}
        {d.initialCancellableStatus === 1 && (
          <div>
            Cancellation {booking.cancellablePrice} {d.currency}
          </div>
        )}
        {booking.electricity && (
          <div>
            Electricity {booking.electricityPrice} {d.currency}
          </div>
        )}
      </li>
    ));
  };
  const BookingBody = ({ booking }) => {
    let b = booking.bookingDeepView;
    return (
      <div>
        <List>
          <li>Berth: {b.label}</li>
          <li>
            Arrival: {DateFunctions.stringToDateString(b.from)}{" "}
            {DateFunctions.stringToTimeString(b.from)}
          </li>
          <li>
            Departure: {DateFunctions.stringToDateString(b.to)}{" "}
            {DateFunctions.stringToTimeString(b.to)}
          </li>
          <li>Cancellable: {b.cancellable ? "Yes" : "No"}</li>
          <li>Electricity: {b.electricity ? "Yes" : "No"}</li>
        </List>
        <b>Price details</b>
        <TotalPrice booking={b} />
        {b.cancellable && (
          <div>
            <Link to={"/cancellation/" + b.id}>Cancel booking</Link>{" "}
          </div>
        )}
        <List>
          <Days booking={b} />
        </List>
        <b>About your boat</b>
        <List>
          <li>
            Boat dimensions: (l, b, d) {b.length} m, {b.width} m, {b.depth} m
          </li>
          <li>Boat model: {b.boatModel}</li>
          <li>Boat name: {b.boatName}</li>
        </List>
      </div>
    );
  };
  const Items = () => {
    return bookings.map((bk, i) => (
      <AccordionItem>
        <AccordionHeader targetId={i}>
          <Container>
            <Row>
              <Col>
                <Container>
                  <Row>
                    <Col>
                      <b>
                        {bk.harbourView.name} - berth {bk.bookingDeepView.label}{" "}
                      </b>
                    </Col>
                  </Row>
                  {bk.bookingDeepView.status === 2 && (
                    <Row>
                      <Col>
                        <b>Cancelled booking</b>
                      </Col>
                    </Row>
                  )}
                  {bk.bookingDeepView.status === 3 && (
                    <Row>
                      <Col>
                        <b>Partially cancelled booking</b>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      Arrival:{" "}
                      {DateFunctions.stringToDateString(
                        bk.bookingDeepView.from
                      )}{" "}
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col>
                {" "}
                <img
                  style={{ "border-radius": "8px" }}
                  src={HarbourFunctions.thumbnailPath(bk.harbourView.image)}
                ></img>{" "}
              </Col>
            </Row>
          </Container>
        </AccordionHeader>
        <AccordionBody accordionId={i}>
          <BookingBody booking={bk} />
        </AccordionBody>
      </AccordionItem>
    ));
  };
  return (
    <Accordion open={open} toggle={toggle}>
      <Items />
    </Accordion>
  );
};

export default FutureBookings;

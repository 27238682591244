import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Form,
  FormGroup,
  Row,
  Col,
  Button,
  ButtonGroup,
  Container,
  Table,
} from "reactstrap";
import "./App.css";

const FormattedDateTime = ({ datestring }) => {
  console.log("Format date:" + JSON.stringify(datestring));

  let theDate = new Date(Date.parse(datestring));
  return (
    <div>
      {theDate.getDate()}/{theDate.getMonth()} {theDate.getFullYear()} 12:00
    </div>
  );
};

const ToBookingDetailsButton = ({ trailSearchResult, harbour, criteria }) => {
  const navigate = useNavigate();
  return (
    <Button
      id="toBookingDetailsButton"
      size="lg"
      color="primary"
      onClick={() => {
        console.log(
          `Clicked proceed with trailSearchResult
                ${JSON.stringify(trailSearchResult)}`
        );
        navigate("/bookingdetails", {
          state: {
            trail: trailSearchResult,
            harbour: harbour,
            criteria: {
              ...criteria,
              arrival: criteria.arrival.setHours(12),
              departure: criteria.departure.setHours(12),
            },
          },
        });
      }}
    >
      Proceed
    </Button>
  );
};
const Trail = ({ trailSearchResult, harbour, criteria }) => {
  console.log(
    `Enter Trail with harbour: ${JSON.stringify(
      harbour.name
    )} and trail  ${JSON.stringify(
      trailSearchResult
    )} with criteria ${JSON.stringify(criteria)}`
  );
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">No single berth available </CardTitle>
        But the following {trailSearchResult.length} can be booked together:
        {trailSearchResult.map((t) => {
          return (
            <div key={t.id}>
              <b>Berth {t.label}</b> from:
              {new Date(Date.parse(t.from)).toLocaleDateString("en-us", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              {"  "} to:{" "}
              {new Date(Date.parse(t.to)).toLocaleDateString("en-us", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          );
        })}
        <ToBookingDetailsButton
          trailSearchResult={trailSearchResult}
          harbour={harbour}
          criteria={criteria}
        />
      </CardBody>
    </Card>
  );
};

export default Trail;

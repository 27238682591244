import { useNavigate } from "react-router-dom";
import { Label, Input, Button } from "reactstrap";
import "./App.css";

const BerthInput = ({
  selectedBerth,
  setSelectedBerth,
  berthSearchResult,
  harbour,
  criteria,
}) => {
  console.log("Enter BerthInput");
  const navigate = useNavigate();
  const berthSelected = () => {
    return selectedBerth && selectedBerth !== "0";
  };
  const LabelF = () => {
    if (berthSelected()) {
      return (
        <Label size="lg" for="berthSelect">
          Chosen berth
        </Label>
      );
    } else {
      return (
        <Label size="lg" for="berthSelect">
          Select one of the available berths
        </Label>
      );
    }
  };
  const ToBookingDetailsButton = () => {
    console.log(
      `ToBookingDetailsButton with criteria: ${JSON.stringify(criteria)}`
    );
    if (berthSelected()) {
      let berthState = berthSearchResult.find((b) => b.id === selectedBerth);
      return (
        <Button
          id="toBookingDetailsButton"
          size="lg"
          color="primary"
          onClick={() => {
            console.log(
              "Clicked payment and selected berth is:" +
                JSON.stringify(berthState) +
                " and criteria: " +
                JSON.stringify(criteria)
            );
            navigate("/bookingdetails", {
              state: {
                selectedBerth: berthState,
                harbour: harbour,
                criteria: criteria,
              },
            });
          }}
        >
          Proceed
        </Button>
      );
    } else {
      return (
        <Button size="lg" color="primary" disabled>
          Proceed
        </Button>
      );
    }
  };
  if (berthSearchResult.filter((b) => b.available).length === 0) {
    return <div>No available berths for the selected period</div>;
  } else {
    return (
      <div>
        <LabelF />
        <Input
          value={selectedBerth}
          type="select"
          name="select"
          id="berthSelect"
          onChange={(event) => setSelectedBerth(event.target.value)}
        >
          <option key="0" value="0">
            Choose berth
          </option>
          {berthSearchResult
            .sort((left, right) => {
              if (left.label < right.label) {
                return -1;
              } else if (left.label > right.label) {
                return 1;
              } else return 0;
            })
            .filter((b) => b.available)

            .map((b) => {
              return (
                <option key={b.id} value={b.id}>
                  {b.label}
                </option>
              );
            })}
        </Input>

        <ToBookingDetailsButton />
      </div>
    );
  }
};

export default BerthInput;

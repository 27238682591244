import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Toast,
  ToastHeader,
  ToastBody,
  Row,
  Col,
  Button,
  Container,
  List,
  Spinner,
} from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";
import { useAuth } from "./AuthContext";
import { HBApi } from "./HBApi";

const BookingCancellationAnonymous = () => {
  const { id } = useParams();
  const [booking, setBooking] = useState({});
  const [dryRun, setDryRun] = useState({});
  const [errorResult, setErrorResult] = useState(null);
  const [cancelComplete, setCancelComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cancelError, setCancelError] = useState(null);
  useEffect(() => {
    setLoading(true);
    HBApi.getBookingByIdNoJson(id)
      .then((response) => {
        if (response.ok) {
          console.log("Response is ok");
          return response.json();
        } else {
          console.log(`Response is not ok`);
          return Promise.reject(response);
        }
      })
      .then((booking) => {
        HBApi.cancelDryRun(id, 0)
          .then((cancelDRResponse) => {
            if (cancelDRResponse.ok) {
              return cancelDRResponse.json();
            } else {
              return Promise.reject(cancelDRResponse);
            }
          })
          .then((dryRunJson) => {
            console.log(
              "Received dryRun response : " + JSON.stringify(dryRunJson)
            );
            HBApi.getHarbourById(booking.harbourId)
              .then((harbourResponse) => {
                if (harbourResponse.ok) {
                  return harbourResponse.json();
                } else {
                  return Promise.reject(harbourResponse);
                }
              })
              .then((harbourJson) => {
                const bAndH = { ...booking, harbourLabel: harbourJson.name };
                setBooking(bAndH);
                setDryRun(dryRunJson);
                setLoading(false);
              })
              .catch((errorResponse) => {
                setLoading(false);
                setErrorResult("An error ocurred");
              });
          })
          .catch((errorResponse) => {
            setLoading(false);
            console.log("errorResponse " + errorResponse.status);
            if (errorResponse.status === 404) {
              setErrorResult("No booking with the provided id found.");
            } else if (errorResponse.status === 500) {
              setErrorResult("An error ocurred");
            }
          });
      });
  }, []);

  const handleCancel = async (event) => {
    event.preventDefault();
    setErrorResult(null);
    setLoading(true);
    HBApi.cancelBooking(booking.id)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          return response.body;
        } else {
          return Promise.reject(response);
        }
      })
      .then((success) => {
        setCancelComplete(true);
      })
      .catch((errorResponse) => {
        setLoading(false);
        if (errorResponse.status === 409) {
          setCancelError(409);
        }
        if (errorResponse.status === 417) {
          setCancelError(417);
        } else if (errorResponse.status === 423) {
          setCancelError(423); // LOCKED means the booking is historic and therefore cannot be cancelled
        } else {
          setErrorResult("An error ocurred");
        }
      });
  };
  const DaysList = () => {
    return booking.days.map((b) => {
      if (booking.electricity) {
        return (
          <li key={b.ordering}>
            Day {b.ordering}, {b.berthPrice + b.bookingPrice} {b.currency} +
            electricity {booking.electricityPrice} {b.currency}
          </li>
        );
      } else {
        return (
          <li key={b.ordering}>
            Day {b.ordering}, {b.berthPrice + b.bookingPrice} {b.currency}
          </li>
        );
      }
    });
  };
  const BookingDryRunResult = () => {
    let cancelledDaysCount = dryRun.daysCancelled.length;
    if (cancelledDaysCount > 0) {
      return (
        <div>
          <li>
            {cancelledDaysCount} booked days will be cancelled and repayed
          </li>
          <DaysList />
        </div>
      );
    } else {
      return booking.days.map((b) => (
        <li key={b.ordering}>
          Day {b.ordering}, {b.status === 2 && "Cancelled"}
          {b.status === 1 && "Confirmed"}
        </li>
      ));
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (cancelError === 423) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>Booking not cancellable</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>
                        The end date for cancelling this booking has passed.
                      </li>
                    </List>
                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelError === 417) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>Booking not cancellable</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>
                        This booking was not done with the cancellable option
                      </li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelError === 409) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>Booking was already cancelled</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>You have already cancelled the booking</li>
                      <li>
                        An email confirmation was sent when the booking was
                        cancelled.
                      </li>
                      <li>
                        Within a couple of days the return will be on your bank
                        account (less the cancellation fee).
                      </li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelComplete) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>Booking successfully cancelled</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>You will get a confirmation email shortly</li>
                      <li>
                        Within a couple of days the return will be on your bank
                        account (less the cancellation fee).
                      </li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (errorResult) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-danger rounded">
                <Toast>
                  <ToastHeader>
                    <b>Booking cancellation</b>
                  </ToastHeader>
                  <ToastBody>
                    {errorResult}
                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>Booking cancellation</b>
                </ToastHeader>
                {dryRun.daysCancelled.length > 0 && (
                  <ToastBody>
                    You are about to cancel your booking for
                    <List>
                      <li>Berth {booking.label} </li>
                      <li>At {booking.harbourLabel}</li>
                      <li>From {booking.from}</li>
                      <li>To {booking.to}</li>

                      <BookingDryRunResult />
                    </List>
                    <Button
                      id="cancelButtonId"
                      color="primary"
                      onClick={handleCancel}
                    >
                      Confirm cancellation
                    </Button>
                    <hr />
                  </ToastBody>
                )}
                {dryRun.daysCancelled.length === 0 && (
                  <ToastBody>
                    <b>No days can be cancelled at this point in time</b>
                    <List>
                      <li key="berth">Berth {booking.label} </li>
                      <li key="harbour">At {booking.harbourLabel}</li>
                      <li key="from">From {booking.from}</li>
                      <li key="to">To {booking.to}</li>
                      <BookingDryRunResult />
                    </List>
                    <hr />
                  </ToastBody>
                )}
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default BookingCancellationAnonymous;

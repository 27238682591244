import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion,
  Badge,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";
import { HBApi } from "./HBApi";
import { dateString, withCustomEndTimeHoursDateString } from "./HBApi";
import { BerthFunctions } from "./BerthFunctions";
import { useAuth } from "./AuthContext";
import Profile from "./Profile";
import DatePicker from "react-datepicker";

const ChildrenHandler = ({ children }) => {
  if (!children) {
    return <Profile />;
  } else {
    return children;
  }
};

const MyPage = ({ children }) => {
  const auth = useAuth();
  const user = auth.getUser();

  return (
    <Container>
      <Row>
        <Col md="12">
          <h1 align="center">User zone</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink id="myProfileId" tag={Link} to="/mypage" active>
                Profile
              </NavLink>
            </NavItem>
            {user && auth.hasRole("WITHBERTH") && (
              <NavItem>
                <NavLink id="myBerthId" tag={Link} to="/mypage/myberth" active>
                  My Berth
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                id="futureBookingsId"
                tag={Link}
                to="/mypage/futurebookings"
                active
              >
                Future bookings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="bookingHistoryId"
                tag={Link}
                to="/mypage/bookingshistory"
                active
              >
                Booking history
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <ChildrenHandler children={children} />
    </Container>
  );
};

export default MyPage;

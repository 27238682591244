import React, { useEffect, useState } from "react";
import "./App.css";
import AppNavbar from "./AppNavbar";
import HarbourImagesList from "./HarbourImagesList";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { HBApi } from "./HBApi";

const Home = () => {
  const initialSearch = { name: "" };
  const [loading, setLoading] = useState(false);
  const [harbours, setHarbours] = useState([]);
  const [searchObject, setSearchObject] = useState(initialSearch);
  const [cookies] = useCookies(["XSRF-TOKEN"]); // <.>
  const handleSubmit = async (event) => {
    event.preventDefault();
    HBApi.searchHarbour(searchObject.name)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        console.log("Fetched " + data.length + " harbours");
        setHarbours(data);
      });
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setSearchObject({ ...searchObject, [name]: value });
  };

  const message = (
    <div>
      <p>Where do you want to dock?</p>
    </div>
  );

  return (
    <Container fluid>
      <Container fluid className="withBgImage">
        <Row>
          <AppNavbar />
        </Row>
        <Row>
          <Col md="2" />
          <Col md="8">
            <div className="home-text-on-image">Where do you want to dock?</div>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  bsSize="md"
                  type="text"
                  name="name"
                  id="searchterm"
                  onChange={handleChange}
                  autoComplete="name"
                  className="w-75"
                />
              </FormGroup>
            </Form>
            <div className="home-text-on-image-2">
              HarbourMe - the premium yachtsman booking experience{" "}
            </div>
          </Col>
          <Col md="2" />
        </Row>
      </Container>
      <Container fluid>
        <HarbourImagesList subharbours={harbours} />
      </Container>
      <CookieConsent
        style={{ background: "#556577" }}
        buttonStyle={{
          background: "#FEFEFE",
          color: "#4e503b",
          fontSize: "13px",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Container>
  );
};

export default Home;

import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Circle, useMap } from "react-leaflet";
import * as L from "leaflet";

const HarbourMap = ({
  coords,
  selectedBerth,
  setSelectedBerth,
  berthSearchResult,
  trailSearchResult,
}) => {
  console.log(
    "Enter HarbourMap with selected berth: " +
      selectedBerth +
      ", coords: " +
      JSON.stringify(coords) +
      " and bearthSearchResult size: " +
      berthSearchResult.length
  );

  const BerthMarkers = () => {
    return berthSearchResult
      .filter((b) => b.enabled)
      .map((berth) => {
        var radius = selectedBerth === berth.id && berth.available ? 4 : 2;
        var color = berth.available ? "green" : "gray";
        if (selectedBerth === berth.id && berth.available) color = "white";
        return (
          <Circle
            key={berth.id}
            center={[berth.latitude, berth.longitude]}
            radius={radius}
            color={color}
            eventHandlers={{
              click: (event) => {
                console.log(
                  "marker clicked for key: " +
                    berth.id +
                    ", previously selected berth " +
                    selectedBerth
                );
                setSelectedBerth(berth.id);
              },
            }}
          />
        );
      });
  };
  const TrailMarkers = () => {
    var map = useMap();
    return trailSearchResult.map((tsv) => {
      return (
        <Circle
          key={tsv.id}
          center={[tsv.latitude, tsv.longitude]}
          radius="4"
          color="white"
        />
      );
    });
  };

  return (
    <MapContainer center={coords} zoom={17} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      if(berthSearchResult.length > 0) {<BerthMarkers />} else
      if(trailSearchResult.length >0) {<TrailMarkers />}
    </MapContainer>
  );
};

export default HarbourMap;

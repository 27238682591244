import {
  berthsAddress,
  berthsSearchAddress,
  myBerthStatusesAddress,
  statusAddress,
  harboursAddress,
  harboursSearchAddress,
  signinAddress,
  signoutAddress,
  forgotPasswordAddress,
  resetPasswordAddress,
  markPasswordTokenFetchedAddress,
  userinfoAddress,
  admininfoAddress,
  boatinfoAddress,
  trailsSearchAddress,
  myBerthsAddress,
  bookingsAddress,
  userFutureBookingAddress,
  cancelBookingAddress,
  csrfAddress,
  signupAddress,
  verifyRegistrationEmailAddress,
  validateEmailAddress,
  mayResendRerificationEmailAddress,
  resendVerificationEmailAddress,
  adminGetBerthStatusFeeSupportAddress,
} from "./HBConstants";

export const HBApi = {
  postWithCsrf,
  postWithCsrfResponse,
  getBerthsForHarbour,
  getMyBerths,
  getHarbourById,
  searchHarbour,
  searchBerthByHarbourAndCriteria,
  signIn,
  signOut,
  signUp,
  forgotPassword,
  resetPassword,
  updateProfile,
  verifyRegistrationEmail,
  getUserInfo,
  getBoatInfo,
  getTrails,
  book,
  bookingDryRun,
  getBookingsByBerth,
  getBookingsById,
  getUserFutureBookings,
  getBookingByIdNoJson,
  getBookingReference,
  getBookingReferences,
  cancelBooking,
  cancelDryRun,
  getBerthStatusesByBerth,
  modifyBerthStatus,
  deleteBerthStatus,
  dateString,
  dateStringFromDate,
  validateEmail,
  mayResendVerification,
  requestResend,
  getAdminBerthStatusForFeeSupport,
  getAdminInfo,
  markPasswordTokenFetched,
};

export function dateStringFromDate(date) {
  return date.toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function dateString(date) {
  return new Date(Date.parse(date)).toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function withCustomEndTimeHoursDateString(date) {
  let d = new Date(Date.parse(date));
  if (d.getHours() === 12) {
    return dateString(date);
  } else {
    return `${dateString(date)} at ${d.getHours()}.00`;
  }
}

const postOptions = (tokenResp, data) => {
  console.log("Enter postOptions with: " + JSON.stringify(tokenResp));
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": tokenResp.token,
    },
    credentials: "include",
    body: JSON.stringify(data),
  };
};

const methodOptions = (methodP, tokenResp, data) => {
  console.log(
    `Enter methodOptions with: ${methodP} and token ${JSON.stringify(
      tokenResp
    )}`
  );
  return {
    method: methodP,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": tokenResp.token,
    },
    credentials: "include",
    body: JSON.stringify(data),
  };
};

export function postWithCsrf(address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) =>
      fetch(address, postOptions(tokenResp, data)).then((response2) =>
        response2.json()
      )
    );
}

export function methodWithCsrfNoJson(method, address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) =>
      fetch(address, methodOptions(method, tokenResp, data))
    );
}

export function postWithCsrfNoJson(address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) => fetch(address, postOptions(tokenResp, data)));
}

export function postWithCsrfResponse(address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) => fetch(address, postOptions(tokenResp, data)));
}

function getMyBerths() {
  console.log("Enter getMyBerths");
  return fetch(`${myBerthsAddress}`, { credentials: "include" });
}

function getBerthsForHarbour(harbourId) {
  return fetch(`${berthsAddress}?harbour=${harbourId}`, {
    credentials: "include",
  });
}

function getHarbourById(id) {
  return fetch(`${harboursAddress}/${id}`, { credentials: "include" });
}

function searchHarbour(searchTerm) {
  return fetch(`${harboursSearchAddress}${searchTerm}`, {
    credentials: "include",
  });
}

function searchBerthByHarbourAndCriteria(
  harbourId,
  arrival,
  departure,
  length,
  width,
  depth
) {
  return fetch(
    `${berthsSearchAddress}?harbour=${harbourId}&length=${length}&width=${width}&depth=${depth}&from=${arrival}&to=${departure}`,
    { credentials: "include" }
  );
}

function signIn(credentials) {
  return postWithCsrfResponse(signinAddress, credentials);
}
function signOut(credentials) {
  return postWithCsrf(signoutAddress, credentials);
}
function signUp(formData) {
  return postWithCsrfResponse(signupAddress, formData);
}

function book(segments, bookingDetails, boatDetails) {
  return postWithCsrfNoJson(bookingsAddress, {
    segments,
    bookingDetails,
    boatDetails,
  });
}

function cancelBooking(idParam) {
  console.log(`Enter HBApi cancelBooking ${idParam}`);
  return postWithCsrfNoJson(cancelBookingAddress, { id: idParam });
}

function cancelDryRun(idParam, fromDayParam) {
  return fetch(
    `${bookingsAddress}/${idParam}/canceldryrun?fromDay=${fromDayParam}`,
    {
      credentials: "include",
    }
  );
}
function getBookingReference(idParam) {
  return fetch(`${bookingsAddress}/${idParam}/reference`, {
    credentials: "include",
  });
}
async function getBookingReferences(idsParam) {
  const responses = await Promise.all(
    idsParam.map((bookingId) => getBookingReference(bookingId))
  );
  const bookingReferencesJson = await Promise.all(
    responses.map((r) => r.json())
  );

  return bookingReferencesJson;
}

function oneSegmentBookingDryRun(trailSegment, criteria) {
  return fetch(
    `${bookingsAddress}/bookingdryrun?from=${trailSegment.from}&to=${trailSegment.to}&berthId=${trailSegment.berthId}&length=${criteria.length}&width=${criteria.width}&depth=${criteria.depth}`,
    {
      credentials: "include",
    }
  );
}
async function bookingDryRun(segments, criteria) {
  const responses = await Promise.all(
    segments.map((s) => oneSegmentBookingDryRun(s, criteria))
  );
  const dryRunJson = await Promise.all(responses.map((r) => r.json()));

  return dryRunJson;
}

function getUserInfo() {
  console.log("Enter HBApi getUserInfo");
  return fetch(`${userinfoAddress}`, { credentials: "include" });
}
function getBoatInfo() {
  console.log("Enter HBApi getBoatInfo");
  return fetch(`${boatinfoAddress}`, { credentials: "include" });
}
function getUserFutureBookings() {
  return fetch(`${userFutureBookingAddress}`, { credentials: "include" });
}
function getTrails(id, newSearchObject) {
  return fetch(
    `${trailsSearchAddress}?harbour=${id}&length=${newSearchObject.length}&width=${newSearchObject.width}&depth=${newSearchObject.depth}&from=${newSearchObject.arrival}&to=${newSearchObject.departure}`,
    { credentials: "include" }
  ).then((response0) => response0.json());
}

function getBookingsByBerth(berthId, from, to) {
  return fetch(`${bookingsAddress}?berthId=${berthId}&from=${from}&to=${to}`, {
    credentials: "include",
  }).then((response) => response.json());
}

function getBookingsById(id) {
  return fetch(`${bookingsAddress}/${id}`, {
    credentials: "include",
  }).then((response) => response.json());
}
function getBookingByIdNoJson(id) {
  return fetch(`${bookingsAddress}/${id}`, {
    credentials: "include",
  });
}

function getBerthStatusesByBerth(berthId, from, to) {
  return fetch(
    `${myBerthStatusesAddress}?berthId=${berthId}&from=${from}&to=${to}`,
    { credentials: "include" }
  ).then((response) => response.json());
}

function modifyBerthStatus(modificationInfo) {
  return postWithCsrfNoJson(statusAddress, modificationInfo);
}

function deleteBerthStatus(id) {
  return methodWithCsrfNoJson("DELETE", statusAddress, id);
}

function verifyRegistrationEmail(id) {
  console.log(`verifyRegistrationEmail with token ${id}`);
  return fetch(`${verifyRegistrationEmailAddress}?token=${id}`, {
    credentials: "include",
  });
}

function validateEmail(emailAddress) {
  return fetch(`${validateEmailAddress}?email=${emailAddress}`, {
    credentials: "include",
  });
}

function mayResendVerification(emailAddress) {
  return fetch(`${mayResendRerificationEmailAddress}?email=${emailAddress}`, {
    credentials: "include",
  });
}

function requestResend(emailAddress) {
  return postWithCsrfNoJson(resendVerificationEmailAddress, {
    email: emailAddress,
  });
}

function updateProfile(arg) {
  console.log(`HBApi.updateProfile : ${JSON.stringify(arg)}`);
  return methodWithCsrfNoJson("PATCH", signupAddress, arg);
}

function forgotPassword(emailAddress) {
  return postWithCsrfNoJson(forgotPasswordAddress, {
    email: emailAddress,
  });
}

function resetPassword(tokenArg, passwordArg) {
  return postWithCsrfNoJson(resetPasswordAddress, {
    password: passwordArg,
    token: tokenArg,
  });
}

function markPasswordTokenFetched(tokenArg) {
  return fetch(`${markPasswordTokenFetchedAddress}?token=${tokenArg}`, {
    credentials: "include",
  });
}

function getAdminBerthStatusForFeeSupport(harbourId, date) {
  return fetch(
    `${adminGetBerthStatusFeeSupportAddress}?harbour=${harbourId}&date=${date}`,
    {
      credentials: "include",
    }
  );
}

function getAdminInfo() {
  return fetch(admininfoAddress, { credentials: "include" });
}
